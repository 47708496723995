import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/index/indexcontent.js";
import Brands from "../sections/homepage/Brands.js";
import Award from "../components/NewToxane.js";
import Treatment from "../sections/index/indextreatments.js";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/index/indexreviews.js";
import Ethics from "../sections/index/indexethics.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import PopupForm from "../components/PopupForm/PopupForm";
import imgVideo from "../assets/image/jpeg/index-hero.jpg";
import Blog from '../sections/index/indexblog.js';
import Dtreat from "../sections/index/treatdekstop.js";

const IndexPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Head title="Experts in Non Surgical Treatments London" image="/images/dermamina-home.jpg"  description="Experts in non surgical nose job Rhinoplasty and skincare treatments London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>

      <JsonLd>
      {{
      '@context': 'https://schema.org',
      '@type': 'Organization',
      '@id': 'https://www.dermamina.com/#organization',
      url: 'https://www.dermamina.com',
      name: 'Dermamina',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '020 7099 6650',
        contactType: 'Customer service',
      },
      address:{
        '@type': 'PostalAddress',
      streetAddress: '114 New Cavendish Street',
      addressRegion:'London',
      postalCode:'W1W 6XT',
      addressLocality:'Westminster',
      },
      image: {
        '@type': 'ImageObject',
        url: 'https://dermamina.com/images/dermamina-home.jpg',
      },
      logo: {
      '@type': 'ImageObject',
      url: 'https://dermamina.com/images/dermamina-logo.svg',
      width: '200',
      },
      'name': 'dermamina',
      'sameAs': [

      'https://www.facebook.com/DermaMina-1985252831708400/',
      'https://www.instagram.com/dermamina',
      ],
      }}
      </JsonLd>

      <JsonLd>
      {{
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {'@type': 'ListItem',
      position:1,
      name:'Dermamina Clinic',
      item:'https://www.dermamina.com',
      },

      {'@type': 'ListItem',
      position:2,
      name:'Non-Surgical Rhinoplasty',
      item:'https://www.dermamina.com/non-surgical-nose-job',
      },

      {'@type': 'ListItem',
      position:3,
      name:'Tear Trough Fillers Under Eye',
      item:'https://www.dermamina.com/tear-trough-under-eye',
      },

      ]
      }}
      </JsonLd>

      <PageWrapper headerDark>

      <PopupForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      <HeroComponent
          itext='<span style="color: #1a1a1a;">Experts in</span> <span style="color: #00aec7;"> Non Surgical Treatments</span>'
          secondText="Look Amazing, Feel Great"
          imgVideo={imgVideo} // Specify your image URL here
          setIsModalOpen={setIsModalOpen}
        />
      <Dtreat />
      <Treatment />
      <Content
        setIsModalOpen={setIsModalOpen}
      />
      <Testimonial />
      <Award />
      <Ethics
        setIsModalOpen={setIsModalOpen}
      />
      <Clinic />
      <Blog />
      <Brands />
      <Saveface />
      <Trend />

      </PageWrapper>
    </>
  );
};

export default IndexPage;
